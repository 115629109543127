import React from "react"
import "./ProductIntroStyle.scss"
import Logo from "../../assets/images/logo.png"
import { Link, navigate } from "gatsby"
import Label_Blaetterteig from "../../assets/images/products/Label_Blaetterteig.png"
import Label_Dinkelteig from "../../assets/images/products/Label_Dinkelteig.png"
import Label_Fruehlingsrollenteig from "../../assets/images/products/Label_Fruehlingsrollenteig.png"
import Label_Maultaschenteig from "../../assets/images/products/Label_Maultaschenteig.png"
import Label_Muerbteig from "../../assets/images/products/Label_Muerbteig.png"
import Label_Strudelteig from "../../assets/images/products/Label_Strudelteig.png"
import { Button } from "../Input/Input"

const ProductIntro = () => {
  return (
    <div className="product-intro-wrapper">
      {/* Mobile Version */}
      <div className="mobile-grid-box-wrapper">
        <img
          src={Logo}
          alt="Rolf Schick GmbH Logo"
          className="main-logo-image"
        />
        <div className="mobile-grid-box">
          <div>
            <Link to="/blaetterteig">
              <img src={Label_Blaetterteig} alt="Schick Blätterteig" />
            </Link>
          </div>
          <div>
            <Link to="/dinkelteig">
              <img src={Label_Dinkelteig} alt="Schick Dinkelteig" />
            </Link>
          </div>
          <div>
            <Link to="/fruehlingsrollenteig">
              <img
                src={Label_Fruehlingsrollenteig}
                alt="Schick Frühlingsrollenteig"
              />
            </Link>
          </div>
          <div>
            <Link to="/maultaschenteig">
              <img src={Label_Maultaschenteig} alt="Schick Maultaschenteig" />
            </Link>
          </div>

          <div>
            <Link to="/muerbteig">
              <img src={Label_Muerbteig} alt="Schick Mürbteig" />
            </Link>
          </div>
          <div>
            <Link to="/strudelteig">
              <img src={Label_Strudelteig} alt="Schick Strudelteig" />
            </Link>
          </div>
        </div>
      </div>

      {/* Desktop Version */}
      <div className="custom-grid-row">
        <div>
          <Link to="/blaetterteig">
            <img src={Label_Blaetterteig} alt="Schick Blätterteig" />
          </Link>
        </div>
        <div>
          <Link to="/dinkelteig">
            <img src={Label_Dinkelteig} alt="Schick Dinkelteig" />
          </Link>
        </div>
      </div>
      <div className="custom-grid-row">
        <div>
          <Link to="/fruehlingsrollenteig">
            <img
              src={Label_Fruehlingsrollenteig}
              alt="Schick Frühlingsrollenteig"
            />
          </Link>
        </div>
        <div>
          <img
            src={Logo}
            alt="Rolf Schick GmbH Logo"
            className="main-logo-image"
          />
        </div>
        <div>
          <Link to="/maultaschenteig">
            <img src={Label_Maultaschenteig} alt="Schick Maultaschenteig" />
          </Link>
        </div>
      </div>
      <div className="custom-grid-row">
        <div>
          <Link to="/muerbteig">
            <img src={Label_Muerbteig} alt="Schick Mürbteig" />
          </Link>
        </div>
        <div>
          <Link to="/strudelteig">
            <img src={Label_Strudelteig} alt="Schick Strudelteig" />
          </Link>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex-box-button-wrapper">
        <Button text="Die Idee" onClick={() => navigate("/idea")} />
        <Button text="Mehr Inhalt" onClick={() => navigate("#section_1")} />
      </div>
    </div>
  )
}

export default ProductIntro
