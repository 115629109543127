import React, { useEffect, useState, useRef } from "react"
import "./ContactStyle.scss"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Offline, Online } from "react-detect-offline"
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3"
import { GOOGLE_SITE_KEY } from "../../utils/recaptcha"
import { Input, Textarea, Button } from "../Input/Input"
import { validateEmail } from "../../utils/validation"
import "isomorphic-fetch"

const Contact = () => {
  /* Cookie stuff for Recaptcha */
  const acceptCookieSet = Cookies.get("AllowSchickCookies") === "true"
  const [acceptCookies, setAcceptCookies] = useState(
    Cookies.get("AllowSchickCookies") === "true"
  )

  const activateCookies = allow => {
    setAcceptCookies(allow)
    if (!allow) {
      Cookies.remove("AllowSchickCookies")
    }
    window.location.reload()
  }

  useEffect(() => {
    if (acceptCookies && acceptCookieSet) {
      loadReCaptcha(GOOGLE_SITE_KEY)
    }
  }, [acceptCookies, acceptCookieSet])

  /* Sending the Mail */
  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [nachricht, setNachricht] = useState("")
  const [isSending, setIsSending] = useState(false)
  const recaptchaRef = useRef(null)
  const [recaptchaToken, setRecaptchaToken] = useState()
  const [buttonText, setButtonText] = useState("Absenden")
  const [isError, setIsError] = useState(false)
  const [isMissingData, setIsMissingData] = useState(false)

  const verifyCallback = recaptchaToken => {
    setRecaptchaToken(recaptchaToken)
  }

  const sendMail = (name, mail, message) => {
    setIsError(false)
    setIsSending(true)
    fetch(
      "/php/mail.php?name=" + name + "&mail=" + mail + "&message=" + message,
      { method: "GET" }
    )
      .then(result => {
        return result.json()
      })
      .then(response => {
        if (response.status === "ok") {
          setName("")
          setMail("")
          setNachricht("")
          setButtonText("Absenden")
        } else {
          setButtonText("Fehler beim Senden")
          setTimeout(() => {
            setButtonText("Absenden")
          }, 5000)
          setIsError(true)
        }
        setIsSending(false)
      })
  }

  return (
    <>
      <ReCaptcha
        sitekey={GOOGLE_SITE_KEY}
        action="action_name"
        ref={recaptchaRef}
        verifyCallback={verifyCallback}
      />
      <CookieConsent
        sameSite="Strict"
        enableDeclineButton
        cookieName="AllowSchickCookies"
        setDeclineCookie={false}
        buttonText="Cookies zustimmen"
        declineButtonText="Cookies ablehnen"
        onAccept={() => {
          activateCookies(true)
        }}
      >
        <h2>Wir nutzen Cookies auf unserer Website.</h2>
        <p className="a-little-bit-of-margin-top">
          Um die Sicherheit dieser Website gegen Bots zu erhöhen nutzen wir den
          Dienst <i>Google Recaptcha Invisible</i>. Hierfür werden Cookies
          benötigt. Wenn Sie hier zustimmen werden Cookies gemäß unserer
          Datenschutzbestimmung gesetzt. Ein Abhlehnen kann den Funktionsumfang
          der Seite beeinträchtigen.
        </p>
      </CookieConsent>

      <div className="contact-wrapper">
        {(acceptCookies && (
          <>
            <Online>
              <form
                className="contact-form"
                onSubmit={event => {
                  event.preventDefault()
                  if (name && nachricht && validateEmail(mail) && !isSending) {
                    fetch("/php/recaptcha.php?token=" + recaptchaToken, {
                      method: "GET",
                    })
                      .then(result => {
                        recaptchaRef.current.execute()
                        return result.json()
                      })
                      .then(response => {
                        setButtonText("Nachricht wird gesendet")
                        if (response.success) {
                          sendMail(name, mail, nachricht)
                        } else {
                          setIsError(true)
                        }
                      })
                      .catch(error => setIsError(true))
                  } else {
                    setIsMissingData(true)
                  }
                }}
              >
                <h2>Sie haben Fragen oder Anregungen?</h2>
                <p className="a-little-bit-of-margin-top a-little-bit-of-margin-bottom">
                  Gerne nehmen wir diese entgegen. Füllen Sie einfach unser
                  Kontaktformular aus. Wir freuen uns darauf von Ihnen zu hören!
                </p>
                <Input
                  type="text"
                  required={true}
                  placeholder="Name *"
                  value={name}
                  onChange={input => setName(input)}
                />
                <Input
                  type="mail"
                  required={true}
                  placeholder="E-Mail-Adresse *"
                  value={mail}
                  onChange={input => setMail(input)}
                />
                <Textarea
                  className="a-little-bit-of-margin-top"
                  placeholder="Ihre Nachricht *"
                  value={nachricht}
                  onChange={input => setNachricht(input)}
                />
                <Button
                  text={buttonText}
                  className="a-little-bit-of-margin-top"
                  onClick={() => {}}
                />
                <p
                  className="contact-error-message"
                  style={isError ? { display: "block" } : { display: "none" }}
                >
                  Es ist ein Fehler beim Senden der Mail aufgetreten. Bitte
                  versuchen Sie es später erneut oder senden Sie bitte direkt
                  eine E-Mail an{" "}
                  <a href="mailto:info@derfeineteig.de">info@derfeineteig.de</a>
                  . Vielen Dank für Ihre Verständnis.
                </p>
                <p
                  className="contact-error-message"
                  style={
                    isMissingData ? { display: "block" } : { display: "none" }
                  }
                >
                  Sie haben keine gültige E-Mail-Adresse eingeben oder Ihr Name
                  ist noch leer.
                  <br />
                  Bitte ergänzen Sie die fehlenden Informationen.
                </p>
              </form>
            </Online>
            <Offline>
              <div className="contact-error-text">
                <h2>Keine Netzwerkverbindung</h2>
                <p className="a-little-bit-of-margin-top">
                  Momentan konnte keine Verbindung zum Kontaktformular
                  hergestellt werden. Versuchen Sie es später bitte erneut.
                </p>
                <p className="a-little-bit-of-margin-top">
                  Gerne können Sie uns auch eine{" "}
                  <a href="mailto:info@derfeineteig.de">E-Mail</a> senden.
                </p>
              </div>
            </Offline>
          </>
        )) || (
          <div className="contact-error-text">
            <h2>Sie haben Fragen oder Anregungen?</h2>
            <p className="a-little-bit-of-margin-top">
              Gerne nehmen wir diese entgegen. Senden Sie uns einfach eine Email
              an <a href="mailto:info@derfeineteig.de">info@derfeineteig.de</a>.
            </p>
            <p className="a-little-bit-of-margin-top">
              Alternativ können Sie auch Cookies akzeptieren und unser
              Kontaktformular nutzen. In jedem Fall freuen wir uns von Ihnen zu
              hören.
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default Contact
