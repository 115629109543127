import React from "react"
import { Offline, Online } from "react-detect-offline"
import Map from "pigeon-maps"
import Overlay from "pigeon-overlay"
import "./LocationMap.scss"
import Logo from "../../assets/images/logo.png"

const LocationMap = () => {
  const customProviders = (x, y, z, dpr) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3))
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
  }

  return (
    <div className="location-map-wrapper">
      <Online>
        <h2 className="a-little-bit-of-margin-bottom">Wo finden Sie uns</h2>
        <Map
          attribution={false}
          provider={customProviders}
          zoom={15}
          animations
          height={500}
          defaultCenter={[49.100047, 9.193183]}
        >
          <Overlay anchor={[49.100047, 9.193183]} offset={[30, 25]}>
            <div className="overlay-wrapper">
              <img src={Logo} alt="Rolf Schick GmbH Logo" />
              <div>
                <p>Mäuswedel 13</p>
                <p>74223 Flein</p>
              </div>
            </div>
          </Overlay>
        </Map>
      </Online>
      <Offline>
        <h2>Keine Netzwerkverbindung</h2>
        <p className="a-little-bit-of-margin-top">
          Momentan konnte keine Verbindung zu OpenStreetMap hergestellt werden.
          Versuchen Sie es später bitte erneut.
        </p>
        <p className="a-little-bit-of-margin-top">
          Gerne können Sie uns auch eine{" "}
          <a href="mailto:info@derfeineteig.de">E-Mail</a> senden.
        </p>
      </Offline>
    </div>
  )
}

export default LocationMap
