import React from "react"
import DotMenu, { DotMenuPage } from "@mjoellnier/dot-menu"
import SEO from "../components/SEO"
import "../style/global.scss"
import "../style/mobil.scss"
import IEFixIntersectionObserver from "../utils/intersection-observer"
import RenderInBrowser from "react-render-in-browser"
import Footer from "../components/Footer/Footer"
import LocationMap from "../components/LocationMap/LocationMap"
import Contact from "../components/Contact/Contact"
import BlurredBuilding from "../assets/images/building_blurred.png"
import ProductIntro from "../components/ProductIntro/ProductIntro"
import PWAPrompt from "../components/PWAPrompt/PWAPrompt"
import "typeface-lato"

const IndexPage = () => {
  const fixedDotMenuStyle = { display: "table-cell", verticalAlign: "middle" }

  return (
    <>
      <SEO title="Startseite" />
      <PWAPrompt />

      {/* Fix for internet explorer */}
      <RenderInBrowser ie only>
        <script src={IEFixIntersectionObserver} />
      </RenderInBrowser>

      {/* Landing page content */}
      <div className="landing-page-wrapper">
        <DotMenu windowScrolling={false} hideDots={false} hidePath={false}>
          <DotMenuPage
            style={fixedDotMenuStyle}
            title={"Oben"}
            backgroundImage={BlurredBuilding}
          >
            <ProductIntro />
          </DotMenuPage>

          <DotMenuPage
            style={fixedDotMenuStyle}
            title={"Kontakt"}
            backgroundColor={"#d0353d"}
          >
            <Contact />
          </DotMenuPage>

          <DotMenuPage
            style={fixedDotMenuStyle}
            title={"So finden Sie uns"}
            backgroundColor={"#d0353d"}
          >
            <LocationMap />
          </DotMenuPage>

          <DotMenuPage
            style={fixedDotMenuStyle}
            title={"Footer"}
            backgroundColor={"#2a2d31"}
          >
            <Footer />
          </DotMenuPage>
        </DotMenu>
      </div>
    </>
  )
}

export default IndexPage
